import { CertificationDocument, Task, Workflow } from "@prisma/client"
import dayjs from "dayjs"
import { humanizeSnakeCase } from "src/helpers/humanizeSnakeCase"

import { dataOrigin } from "./forms"
import { useCaseDetails } from "./forms"
import { dataDestination } from "./forms"

export const getMilestoneStatus = (tasks: Task[]): "DONE" | "IN_PROGRESS" | "NOT_STARTED" => {
  if (tasks.length === 0) {
    return "NOT_STARTED"
  }

  if (tasks.every((t) => t.status === "DONE")) {
    return "DONE"
  }

  if (tasks.find((t) => ["IN_PROGRESS", "DONE"].includes(t.status))) {
    return "IN_PROGRESS"
  }

  return "NOT_STARTED"
}

export enum WorkflowStatus {
  IN_PROGRESS = "IN PROGRESS",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  CANCELLED = "CANCELLED",
}

export const getWorkflowStatus = (workflow: Workflow & { certificationDocument: CertificationDocument | null }): WorkflowStatus => {
  if (workflow.isCancelled) {
    return WorkflowStatus.CANCELLED
  }

  if (!workflow.certificationDocument) {
    return WorkflowStatus.IN_PROGRESS
  }

  const expirationDate = workflow.certificationDocument?.expiresAt

  if (dayjs(expirationDate).isBefore(dayjs())) {
    return WorkflowStatus.INACTIVE
  }

  return WorkflowStatus.ACTIVE
}

export const MilestoneNames = ["Security and Legal", "Data Upload", "Integral Analysis", "Data Changes", "Expert Certification"]

export const MilestoneTasks = {
  "Data Upload": ["Upload Data Dictionary"],
  "Security and Legal": ["Submit client intake form", "Upload security documentation", "Sign Business Associate Agreement"],
}

export enum IntakeRequirementType {
  use_case_details = "use_case_details",
  data_dictionary = "data_dictionary",
  data_origin = "data_origin",
  data_destination = "data_destination",
  data_flow_diagrams = "data_flow_diagrams",
  existing_expert_report = "existing_expert_report",
}

// TODO: Deprecate uses of this in favor of the enum
export const IntakeRequirementTypes = {
  use_case_details: IntakeRequirementType.use_case_details,
  data_dictionary: IntakeRequirementType.data_dictionary,
  data_origin: IntakeRequirementType.data_origin,
  data_destination: IntakeRequirementType.data_destination,
  data_flow_diagrams: IntakeRequirementType.data_flow_diagrams,
  existing_expert_report: IntakeRequirementType.existing_expert_report,
}

export const IntakeRequirementOrder = [
  IntakeRequirementTypes.use_case_details,
  IntakeRequirementTypes.data_flow_diagrams,
  IntakeRequirementTypes.data_origin,
  IntakeRequirementTypes.data_destination,
  IntakeRequirementTypes.data_dictionary,
  IntakeRequirementTypes.existing_expert_report,
]

export const OptionalIntakeRequirementTypes = [IntakeRequirementTypes.data_dictionary, IntakeRequirementTypes.existing_expert_report]

export const humanizeRequirementTitle = (type: string): string => {
  if (type === "data_flow_diagrams") {
    return "Data / flow diagrams"
  }

  return humanizeSnakeCase(type)
}

export const IntakeRequirementForms = {
  [IntakeRequirementTypes.use_case_details]: useCaseDetails,
  [IntakeRequirementTypes.data_origin]: dataOrigin,
  [IntakeRequirementTypes.data_destination]: dataDestination,
}

export const IntegralOrganizationIds = {
  development: 1,
  staging: 34,
  production: 46,
}

export const LoadingDockWorkflowUuids = {
  staging: "83e52829-5bad-4ada-9c1d-b697a082648f",
  production: "b3e8dab9-058a-46ae-814e-a079a008cf43",
}
