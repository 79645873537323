export const useCaseDetails = {
  fields: [
    {
      id: 1,
      question: "Enter the name of the company pursuing this Expert Determination.",
      type: "text",
      required: true,
      placeholder: "eg: Acme Pharma",
    },
    {
      id: 2,
      question: "Characterize the company’s industry.",
      type: "multiple_choice",
      options: [
        {
          value: "1",
          label: "Pharmaceutical",
        },
        {
          value: "2",
          label: "Technology",
        },
        {
          value: "3",
          label: "Data provider",
        },
        {
          value: "4",
          label: "Other",
        },
      ],
      required: true,
    },
    {
      id: 3,
      question: "Characterize the company's focus.",
      type: "text",
      placeholder: "eg: Acme Pharma Inc creates and distributes medications for cardiovascular diseases.",
      required: true,
      multiline: true,
    },
    {
      id: 4,
      question:
        "Which of the following represents this workflow’s use case? Please check all that apply or be as descriptive as possible in the free text field as this is a critical first step in implementation.",
      type: "multiple_choice",
      required: true,
      options: [
        {
          value: "1",
          label: "Produce a combined dataset for sharing",
        },
        {
          value: "2",
          label: "Produce insights based on a combined dataset",
        },
        {
          value: "3",
          label: "Produce a combined dataset for analysis",
        },
        {
          value: "4",
          label: "Train algorithmic models",
        },
        {
          value: "5",
          label: "Other",
        },
      ],
    },
    {
      id: 5,
      question: "Describe how the dataset will be used post-determination. Provide as much detail as possible about the use case and its business applications.",
      type: "text",
      required: true,
      placeholder:
        "eg: Acme Pharma will send the in-scope data to a Snowflake clean room and the goal is to study the effectiveness of an ad campaign by tracking the journey from advertisement exposure to filing a health insurance claim.",
      multiline: true,
    },
    {
      id: 6,
      question: "If there are particular fields that are most important to retain, enter them here (up to 3).",
      type: "text",
      required: false,
      placeholder: "eg: claim_date, procedure_code, provider_id.",
    },
  ],
  state: {
    "1": "",
    "2": [],
    "3": "",
    "4": "",
    "5": "",
  },
}

export const dataOrigin = {
  "fields": [
    {
      "id": 1,
      "question": "Select all that describe the inbound data.  This will inform which risk model is applied by Integral.",
      "type": "multiple_choice",
      "required": true,
       "options": [
        {
          "value": "1",
          "label": "Internally generated/collected (First Party)"
        },
        {
          "value": "2",
          "label": "Externally sourced (Third Party)"
        },
        {
          "value": "3",
          "label": "De-identified"
        },
        {
          "value": "4",
          "label": "Tokenized"
        }
      ]
    },
    {
      "id": 2,
      "question": "Describe the dataset(s).",
      "type": "text",
      "placeholder":"eg: Online site data, claims data, SDOH data, and oncology data",
      "required": true
    },
    {
      "id": 3,
      "question": "What types of institutions was data sourced from?",
      "type": "multiple_choice",
      "required": true,
      "options": [
        {
          "value": "1",
          "label": "Public Data Sources"
        },
        {
          "value": "2",
          "label": "Data vendors"
        },
        {
          "value": "3",
          "label": "Government Data Sources"
        },
        {
          "value": "4",
          "label": "Other"
        }
      ],
      "conditions": [
        {
          "field": 1,
          "operator": "includes",
          "value": "2"
        }
      ]
    },
    {
      "id":4,
      "question": "Describe the other institution type data was sourced from.",
      "type": "text",
      "required": true,
      "conditions": [
        {
          "field": 3,
          "operator": "includes",
          "value": "4"
        }
      ]
    },
    {
      "id": 5,
      "question": "List the sources by name, if relevant.",
      "type": "text",
      "required": false,
      "placeholder": "eg: TradeDesk, Health Union...",
      "conditions": [
        {
          "field": 1,
          "operator": "includes",
          "value": "2"
        }
      ]
    },
    {
      "id": 6,
      "question": "Describe the type of tokenization applied to the data.",
      "type": "single_choice",
      "required": true,
      "options": [
        {
          "value": "1",
          "label": "Datavant token"
        },
        {
          "value": "2",
          "label": "LiveRamp RampID"
        },
        {
          "value": "3",
          "label": "Skyflow ID/token"
        },
        {
          "value": "4",
          "label": "Other"
        }
      ],
      "conditions": [
        {
          "field": 1,
          "operator": "includes",
          "value": "4"
        }
      ]
    },
    {
      "id":7,
      "question": "Describe the alternative tokenization applied.",
      "type": "text",
      "required": true,
      "conditions": [
        {
          "field": 6,
          "operator": "equals",
          "value": "4"
        }
      ]
    }
  ],
  "state": {
    "1": "",
    "2": []
  }
}

export const dataDestination = {
  fields: [
    {
      id: 1,
      question: "If multiple datasets are to be linked, how will they be joined?.",
      type: "single_choice",
      required: true,
      options: [
        {
          value: "1",
          label: "Datavant token",
        },
        {
          value: "2",
          label: "LiveRamp RampID",
        },
        {
          value: "3",
          label: "Skyflow ID/token",
        },
        {
          value: "4",
          label: "Other",
        },
        {
          value: "4",
          label: "N/A, not joining",
        },
      ],
    },
    {
      id: 2,
      question: "Describe the end destination for the transformed data.",
      type: "text",
      placeholder: "eg: The certified data will be housed in Acme Pharma’s clean room",
      required: true,
    },
    {
      id: 3,
      question: "Which organization will be responsible for managing the transformed data?",
      type: "text",
      placeholder: "eg: Acme Pharma has set up the clean room and will allocate permissions to the transformed data",
      required: true,
      multiline: true,
    },
    {
      id: 4,
      question: "What type of external organizations will have access to the data post-determination? This will inform the appropriate level of risk evaluation.",
      type: "multiple_choice",
      options: [
        {
          value: "1",
          label: "Third-party analytics infrastructure (ex: clean room provider)",
        },
        {
          value: "2",
          label: "Outsourced consultants (ex: Deloitte, ZS, Cardinal Path)",
        },
        {
          value: "3",
          label: "Other",
        },
      ],
      required: true,
    },
    {
      id: 5,
      question: "Describe the organizations and teams that will have access to the transformed data.",
      type: "text",
      placeholder: "eg: The compliance team from company A and the analytics team from company B will have access",
      required: true,
      multiline: true,
    },
  ],
  state: {
    "1": "",
    "2": [],
    "3": "",
    "4": "",
    "5": "",
  },
}
