import { Flex, Box } from "@chakra-ui/react"

import SecondaryButton from "../Buttons/Secondary"
import { H4 } from "../Text"

export interface TableFooterProps {
  pageIndex: number
  setPageIndex: (arg: any) => void
  offset: number
  count: number
  hasMore: boolean
  footerTitle?: string
}

const TableFooter: React.FC<TableFooterProps> = ({ pageIndex, setPageIndex, offset: pageSize, count: total, hasMore, footerTitle = "files" }) => {
  const handleClick = (direction: number): void => {
    setPageIndex((prev) => prev + 1 * direction)
  }

  const resultsCopy = (): string => {
    const firstVal = (pageIndex - 1) * pageSize + 1
    const lastVal = Math.min(pageIndex * pageSize, total)

    if (total < pageSize) {
      return `${lastVal} of ${total} ${footerTitle}`
    }

    return `${firstVal}-${lastVal} of ${total} ${footerTitle}`
  }

  return (
    <Flex backgroundColor="var(--table-background)" borderTop="1px solid var(--card-border)" padding="8px 16px" justify="space-between" align="center">
      <Flex align="center">
        <Box paddingRight="16px">
          <H4 style={{ color: "var(--text2)" }}>{resultsCopy()}</H4>
        </Box>
      </Flex>

      <Box>
        <SecondaryButton isDisabled={pageIndex <= 1} onClick={() => setPageIndex(1)} marginRight="8px">
          First
        </SecondaryButton>

        <SecondaryButton isDisabled={pageIndex <= 1} onClick={() => handleClick(-1)} marginRight="8px">
          Previous
        </SecondaryButton>

        <SecondaryButton isDisabled={!hasMore} onClick={() => handleClick(1)} marginRight="8px">
          Next
        </SecondaryButton>
        <SecondaryButton isDisabled={!hasMore} onClick={() => setPageIndex(Math.ceil(total / pageSize))}>
          Last
        </SecondaryButton>
      </Box>
    </Flex>
  )
}

export default TableFooter
