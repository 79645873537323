import { ReactNode } from "react"

import { Box, Skeleton } from "@chakra-ui/react"

import TableFooter from "./TableFooter"
import TableHeader, { ColumnHeader } from "./TableHeader"

export interface FooterDataProps {
  pageIndex: number
  setPageIndex: (arg: number) => void
  offset: number
  count: number
  hasMore: boolean
  footerTitle?: string
}

interface TableDataProps {
  columnHeaders: ColumnHeader[]
  children: ReactNode[]
  footerData?: FooterDataProps
  headerIndent?: string
  columnTooltipText?: string[]
  isLoading?: boolean
  showCheckbox?: boolean
  marginTop?: string
  emptyState?: {
    tableBody?: ReactNode
    showHeader?: boolean
  }
}

const Table = ({
  columnHeaders,
  children,
  footerData,
  headerIndent = "16px",
  isLoading = false,
  showCheckbox = false,
  marginTop = "16px",
  emptyState = {
    showHeader: false,
    tableBody: <></>,
  },
}: TableDataProps) => {
  if (!children?.length && !emptyState.showHeader) {
    return <></>
  }

  const showPagination = footerData && footerData.count > footerData.offset

  const skeletonRows = Array.from({ length: 5 }, (_, index) => (
    <tr key={index} style={{ borderTop: "1px solid #E2E8F0", pointerEvents: "none" }}>
      <td style={{ padding: "10px" }} colSpan={showCheckbox ? columnHeaders.length + 1 : columnHeaders.length}>
        <Skeleton height="40px" />
      </td>
    </tr>
  ))

  const renderTableBody = () => {
    if (isLoading) {
      return skeletonRows
    }

    if (!children?.length) {
      return (
        <tr>
          <td colSpan={showCheckbox ? columnHeaders.length + 1 : columnHeaders.length}>{emptyState.tableBody}</td>
        </tr>
      )
    }

    return children
  }

  return (
    <Box width="100%" border="1px solid var(--card-border)" borderRadius="8px" mt={marginTop} overflowX="auto">
      <table
        style={{
          width: "100%",
          textAlign: "left",
          borderCollapse: "collapse",
          borderRadius: showPagination ? "8px 8px 0 0" : "8px",
          overflow: "hidden",
        }}
      >
        <TableHeader columnHeaders={columnHeaders} showCheckbox={showCheckbox} headerIndent={headerIndent} />
        <tbody>{renderTableBody()}</tbody>
      </table>

      {showPagination && (
        <TableFooter
          pageIndex={footerData.pageIndex}
          setPageIndex={footerData.setPageIndex}
          offset={footerData.offset}
          count={footerData.count}
          hasMore={footerData.hasMore}
          footerTitle={footerData.footerTitle}
        />
      )}
    </Box>
  )
}

export default Table
