export const humanizeSnakeCase = (snakeCasedString: string): string => {
  const words = snakeCasedString.split("_")

  return words
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
      return word.toLowerCase()
    })
    .join(" ")
}
