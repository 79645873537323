import { Icon, Text } from "@chakra-ui/react"
import dayjs from "dayjs"
import { ExtendedIntakeRequirement } from "pages/app/workflow/[workflowUuid]/requirements"
import { HStack } from "@chakra-ui/react"
import { FiDownload, FiTrash2 } from "react-icons/fi"
import { useToastContext } from "src/hooks/useToastContext"
import { handleClientSideException } from "lib/errors"
import { File, IntakeDocument } from "@prisma/client"
import { shortenCopyDisplay } from "src/helpers/shortenCopyDisplay"

import TertiaryButton from "../Buttons/Tertiary"
import Tooltip from "../Indicators/Tooltip"

export type ExtendedIntakeDocument = IntakeDocument & {
  file?: File
  intakeRequirement: ExtendedIntakeRequirement
}

export const humanizeRequirementType = (type: string) => {
  switch (type) {
    case "soc2":
      return "SOC2"
    case "security_documentation":
      return "Security documentation"
    case "data_management_and_handling_confirmation":
      return "Data management and handling confirmation"
    case "data_dictionary":
      return "Data dictionary"
    default:
      return type
  }
}

const IntakeDocumentRow = ({
  intakeDocument,
  onDeleteIntakeDocument,
  showLegalRequirement,
}: {
  intakeDocument: ExtendedIntakeDocument
  onDeleteIntakeDocument: () => void
  showLegalRequirement: boolean
}) => {
  const { showError } = useToastContext()

  const file = intakeDocument.file

  const handleClickDownload = async (e): Promise<void> => {
    e.stopPropagation()

    try {
      // We use fetch instead of axios in these two click handlers due to the .blob() method on the native fetch response object.
      const response = await fetch(`/api/files/${file?.reference}`)

      if (response.status !== 200) {
        throw new Error("There was an issue downloading the file")
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")

      a.href = url
      a.download = file?.name ?? ""
      a.click()
    } catch (error) {
      handleClientSideException(error, "There was an issue downloading the file", showError)
    }
  }

  const [fileNameText, _] = shortenCopyDisplay(file?.name ?? "", 8)

  return (
    <tr
      style={{
        borderTop: "1px solid var(--card-border)",
      }}
      data-requirement-id={intakeDocument.intakeRequirement.id}
      className="intake-requirement"
    >
      {showLegalRequirement && (
        <td
          style={{
            padding: "20px 0px",
            paddingLeft: "24px",
          }}
        >
          {humanizeRequirementType(intakeDocument.intakeRequirement.type)}
        </td>
      )}
      <td
        style={{
          padding: "0px",
          paddingLeft: showLegalRequirement ? "0px" : "24px",
          maxWidth: "140px",
        }}
      >
        {file?.name ? (
          <Tooltip label={file?.name}>
            <Text>{fileNameText}</Text>
          </Tooltip>
        ) : (
          <Text>N/A</Text>
        )}
      </td>
      <td style={{ padding: "0px", paddingRight: "40px" }}>
        {file?.name && (
          <HStack>
            <TertiaryButton tooltip="Download file" onClick={handleClickDownload}>
              <Icon as={FiDownload} />
            </TertiaryButton>
            <TertiaryButton ml="-16px" tooltip="Remove file" onClick={onDeleteIntakeDocument}>
              <Icon as={FiTrash2} />
            </TertiaryButton>
          </HStack>
        )}
      </td>
      <td
        style={{
          padding: "12px 0px",
        }}
      >
        {intakeDocument.intakeRequirement.lastEditedAt ? dayjs(intakeDocument.intakeRequirement.lastEditedAt).format("MM/DD/YYYY") : "N/A"}
      </td>
      <td
        style={{
          padding: "12px 0px",
        }}
      >
        {intakeDocument.intakeRequirement.lastEditedBy?.name}
      </td>
    </tr>
  )
}

export default IntakeDocumentRow
