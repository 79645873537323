import { ReactNode } from "react"

import { HStack } from "@chakra-ui/react"
import { FiInfo } from "react-icons/fi"

import { H4 } from "../Text"
import Tooltip from "../Indicators/Tooltip"

export interface ColumnHeader {
  label: string
  tooltip?: string
  align?: "center" | "left" | "right"
  customHeader?: ReactNode
  width?: string
  onClick?: () => void
}

interface TableHeaderProps {
  columnHeaders: ColumnHeader[]
  showCheckbox?: boolean
  headerIndent?: string
}

const TableHeader = ({ columnHeaders, showCheckbox = false, headerIndent = "16px" }: TableHeaderProps) => {
  return (
    <thead>
      <tr
        style={{
          backgroundColor: "var(--table-background)",
        }}
      >
        {showCheckbox && <th style={{ width: "40px", paddingLeft: headerIndent }}></th>}
        {columnHeaders.map((columnHeader, i) => (
          <th
            key={columnHeader.label}
            style={{
              padding: "8px 0",
              paddingLeft: i === 0 && !showCheckbox ? headerIndent : "0",
              paddingRight: "16px",
              textAlign: columnHeader.align || "left",
              width: columnHeader.width || "auto",
              whiteSpace: "nowrap",
            }}
          >
            {columnHeader.customHeader ? (
              columnHeader.customHeader
            ) : columnHeader.tooltip ? (
              <HStack>
                <H4
                  style={{
                    color: "var(--text2)",
                    cursor: columnHeader.onClick ? "pointer" : "default",
                  }}
                  onClick={columnHeader.onClick}
                >
                  {columnHeader.label}
                </H4>
                <Tooltip label={columnHeader.tooltip}>
                  <span>
                    <FiInfo color="var(--text2)" />
                  </span>
                </Tooltip>
              </HStack>
            ) : (
              <H4
                style={{
                  color: "var(--text2)",
                  cursor: columnHeader.onClick ? "pointer" : "default",
                }}
                onClick={columnHeader.onClick}
              >
                {columnHeader.label}
              </H4>
            )}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
